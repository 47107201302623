import React from 'react';
import { Table, Badge, Row, Col, CardImg } from 'reactstrap';
import moment from "moment";

// Include Translation tool
import { useTranslate } from "react-translate";

//Import the Icons
import Icon from "components/Icons/Icon";
import { useSelector } from 'react-redux';

const KiLabEditor_stored_list = () => {
    // Translation library
    const tEditor = useTranslate('kilab-editor-stored-list');

    const study = useSelector(state => state.kiLabReducer.study);
    const reviews = study && study.comment_history ? study.comment_history.map((r,i) => {
        return {
            date: r.created_at,
            title: r.data.comment_type === 2 ? tEditor("dr-text-ai-result") : r.data.comment_type === 1 ? tEditor("ai-result") : tEditor("dr-text"),
            bornDate: "1978-11-09T23:49:02.771Z",
            aiPureResult: r.data.comment_type === 1,
            isAutoSaveDraft: r.data.comment_type === 0,
            description: r.data.text,
            status: "Nicht abgerechnet",
            pendingTests: r.folgenValues,
            statusColor: "danger",
            physician: (r.doctor.title?r.doctor.title+" ":"")  + r.doctor.firstName + " " + r.doctor.lastName
        }
    }) : [];

    return (
    <>
        {
            reviews.map((result, index) => (
                <div className="review-card" key={`review_${index}`}>
                
                <Row>
                    <Col sm={6} md={6}  className="headerListRow">

                        <span className="review-date ">
                            {moment(result.date ? result.date : result.date).format(`DD-MM-YYYY HH:mm`)}
                        </span>
                        <span className={`review-ki${!result.aiPureResult ? "-edited": ""}`}>{result.title}</span>

                    </Col>
                    <Col sm={6} md={6}>
                        <span className="store-info">{tEditor("saved")} {tEditor("byDr")} <b>{result.physician}</b></span>
                    </Col>
                    <Col md={1} className="align-right">
                        {/*
                            result.isAutoSaveDraft ? (

                                <span className="draft-icon"><Icon file="draft_.svg" /></span>
                            ):(

                                <span className="draft-icon"></span>
                            )
                            */
                        }  
                    </Col>
                </Row>                       
                <Row>
                    <Col lg={9} md={12} sm={12}>
                        <div className="review-content" dangerouslySetInnerHTML={{__html: result.description}}></div>
                    </Col>
                    <Col lg={3} md={0} sm={0} className="containerFolgen">
                        <div className="review-left-header">
                            <p>{tEditor("pending")}</p>
                        </div>
                        <div className="folgen-section">
                            {result.pendingTests.map( (resultFV, indexFV) => {
                                let value = resultFV["Observation Identifier"].replace(/^([\s]*)\s/, '').split(' ');
                                return (
                                <div className="folgen-pill" key={`folgen-pill-${indexFV}`}>
                                    {`${value[1]} ${value[2]}`}
                                </div>
                                )
                            })}
                        </div>
                    </Col>
                </Row>
            </div>
            ))
        }
    </>
    )
}
export default KiLabEditor_stored_list;