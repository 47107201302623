/**
 * View to show statistics
 */

import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";

//Include Translation tool
import { useTranslate } from "react-translate";

//Include a particular scss for this view
import 'assets/scss/custom/_stats.scss';

import WidgetDoughnut from '../../views/Widgets/WidgetDoughnut';

import { useDispatch, useSelector } from "react-redux";

import { actionPracticeMetrics } from "store/actions/statsActions"
import practicesReducer from "store/reducers/practicesReducer";
import { conditionallyUpdateScrollbar } from "reactstrap/lib/utils";

const Stats = () => {

        //Invoke to translate lib
        const t = useTranslate('stats');

        //Hoock para que el componente llame a Redux y pueda invocar acciones
        const dispatch = useDispatch();

        //Obtengo el ID del a Practice
        const activePracticeId = useSelector(state => state.practicesReducer.practice._id) || 0;

        //Obtengo el auth User Id
        const user = useSelector(state => state.userReducer);

        //Practice metrics
        let title1 = ""; 
        let data1 = [];   

        let title2 = "";
        let data2 = [];


        //User Metrics
        let title3 = ""; 
        let data3 = [];   

        let title4 = "";
        let data4 = [];

        


        //obtengo los datos de las metricas orientados a la practice activa
        useEffect(() => {

                //Questions to get from the Back
                const keyQuestion1 = "AI_COMMENT_TECHNICALLY_CORRECT?";
                const keyQuestion2 = "AI_COMMENTARY_COMPLETE?";
                const keyQuestionComposite = [keyQuestion1, keyQuestion2]

                /** TODO recuperar el id del usuario logueado
                 * TODO
                 * TODO
                 * TODO
                 * mostrar los votos particulares que estan dentro de practices - particularVotes:
                 */
                const doctorAuthId = user.auth_user_id; //"675b212229f06e00611a6d24";
                
                dispatch( actionPracticeMetrics(keyQuestionComposite, activePracticeId, doctorAuthId) )
           // [] indica se usa solo una vez y al inicio de la vista
        }, []);
        //console.log("Practice active: ", activePracticeId)

        //Get data from the STATE
        const metricsData = useSelector( (state) => state.statsReducer.data);

      



        /**
         * Method to convert the json in the right format for the graphics components
         * From : JSON from the API
         * TO :
         *       [
         *         {label:"Ja",           value:85,  bgColor:"#7edd65",  hoverColor:"#e5fed3"},
         *         {label:"Weiss nicht",  value:10,  bgColor:"#acc6f6",  hoverColor:"#edf5fe"},
         *         {label:"Nein",         value:35,  bgColor:"#f79477",  hoverColor:"#f7ebca"}
         *       ]
         * @param {} questions 
         * @returns 
         */
        const processStatistics = (questions) => {
                return questions.map((question) => {
                    // General total votes
                    const generalVotes = question.answers.reduce((sum, answer) => sum + answer.totalVotes, 0);
            
                    // General statistics
                    const general = question.answers.map((answer) => ({
                                label: t(answer.keyAnswer),
                                value: ((answer.totalVotes / generalVotes) * 100).toFixed(2),
                                bgColor: answer.bgColor,
                                hoverColor: answer.hoverColor
                    }));
            
                    // Practice statistics
                    let practice = []
                    
                    //To get the practices metrics, set to true the conditional
                    //Otherwise we process without sens.
                    if( false ){
                          const practiceData = question.answers.flatMap((answer) => 
                              answer.practices.map((practice) => ({
                                      label: t(answer.keyAnswer),     
                                      votes: practice.practiceTotalVotes,
                                      bgColor: answer.bgColor,
                                      hoverColor: answer.hoverColor
                              }))
                          );
                  
                          const totalPracticeVotes = practiceData.reduce((sum, practice) => sum + practice.votes, 0);
                  
                          practice = practiceData.map((practice) => ({
                                      label: practice.label,
                                      value: ((practice.votes / totalPracticeVotes) * 100).toFixed(2),
                                      bgColor: practice.bgColor,
                                      hoverColor: practice.hoverColor
                          }));
                    }
                    
            
                    // Doctor statistics
                    const doctorData = question.answers.flatMap((answer) =>
                        answer.practices.flatMap((practice) => 
                            practice.particularVotes.map((vote) => ({
                                label: t(answer.keyAnswer),
                                votes: vote.votes,
                                bgColor: answer.bgColor,
                                hoverColor: answer.hoverColor
                            }))
                        )
                    );
            
                    const totalDoctorVotes = doctorData.reduce((sum, doctor) => sum + doctor.votes, 0);
            
                    const doctor = doctorData.map((doctor) => ({
                        label: doctor.label,
                        value: ((doctor.votes / totalDoctorVotes) * 100).toFixed(2),
                        bgColor: doctor.bgColor,
                        hoverColor: doctor.hoverColor
                    }));
            
                    return {
                        question: question.questionKey,
                        general,
                        practice,
                        doctor
                    };
                });
            };
            
        /*
        const processStatistics = (questions) => {
                return questions.map((question) => {
                    const generalVotes = question.answers.reduce((sum, answer) => sum + answer.totalVotes, 0);
            
                    // General statistics
                    const general = question.answers.map((answer) => ({
                        label: t(answer.keyAnswer),
                        value: ((answer.totalVotes / generalVotes) * 100).toFixed(2),
                        bgColor: answer.bgColor,
                        hoverColor: answer.hoverColor
                    }));

                    // Practice statistics
                    const practiceVotes = question.answers.reduce((acc, answer) => {
                        answer.practices.forEach((practice) => {
                            acc.totalVotes += practice.practiceTotalVotes;
                            acc.answers.push({
                                label: t(answer.keyAnswer),
                                votes: practice.practiceTotalVotes,
                                bgColor: answer.bgColor,
                                hoverColor: answer.hoverColor
                            });
                        });
                        return acc;
                    }, { totalVotes: 0, answers: [] });
            
                    const practice = practiceVotes.answers.map((answer) => ({
                        label: t(answer.label),
                        value: ((answer.votes / practiceVotes.totalVotes) * 100).toFixed(2),
                        bgColor: answer.bgColor,
                        hoverColor: answer.hoverColor
                    }));
            
                    // Doctor statistics
                    const doctorVotes = question.answers.reduce((acc, answer) => {
                        answer.practices.forEach((practice) => {
                            practice.particularVotes.forEach((vote) => {
                                acc.totalVotes += vote.votes;
                                acc.answers.push({
                                    keyAnswer: answer.keyAnswer,
                                    label: t(answer.keyAnswer),
                                    votes: vote.votes,
                                    bgColor: answer.bgColor,
                                    hoverColor: answer.hoverColor
                                });
                            });
                        });
                        return acc;
                    }, { totalVotes: 0, answers: [] });
            
                    const doctor = doctorVotes.answers.map((answer) => ({
                        label: answer.label,
                        value: ((answer.votes / doctorVotes.totalVotes) * 100).toFixed(2),
                        bgColor: answer.bgColor,
                        hoverColor: answer.hoverColor
                    }));
            
                    // Combine results
                    return {
                        question: question.questionKey,
                        general,
                        practice,
                        doctor
                    };
                        });
                };
                */
            
          
              
                //wait for the object from the backend
                if (metricsData && metricsData.length) {

                        // Process data
                        const result = processStatistics(metricsData);

                        /*
                        console.log(JSON.stringify(result, null, 2));  
                        console.log("")
                        console.log("")
                        console.log("")
                        */

                        //Fill the practioce metrics
                        title1 = t(result[0].question);   //dat1.title; 
                        data1 = result[0].doctor;     //dat1.data;   
                        
                        //console.log( data1 )

                        title2 = t(result[1].question);   //dat2.title;
                        data2 = result[1].doctor      //;dat2.data;


                        //**************************** */

                        //Fill the practioce metrics
                        title3 = t(result[0].question);   //dat1.title; 
                        data3 = result[0].general;     //dat1.data;   
                        
                        //console.log( data1 )

                        title4 = t(result[1].question);   //dat2.title;
                        data4 = result[1].general      //;dat2.data;



                }


           
        
               
/*
                let title1 = data1.title; //"AI_COMMENTARY_COMPLETE?";
                let data1 = data1.data;   /*[{  label:"Ja",       value:85,  bgColor:"#7edd65",  hoverColor:"#e5fed3",
                        },{ label:"Weiss nicht",  value:10,  bgColor:"#acc6f6",  hoverColor:"#edf5fe",
                        },{ label:"Nein",         value:35,  bgColor:"#f79477",  hoverColor:"#f7ebca",
                }];*/

                //console.log(data1)
/*
                let title2 = data2.title;//"AI_COMMENT_TECHNICALLY_CORRECT?";
                let data2 = data2.data;/*[{  label:"Ja",       value:27,  bgColor:"#7edd65",  hoverColor:"#e5fed3",
                        },{ label:"Weiss nicht",  value:23,  bgColor:"#acc6f6",  hoverColor:"#edf5fe",
                        },{ label:"Nein",         value:50,  bgColor:"#f79477",  hoverColor:"#f7ebca",
                }];*/

        return (
        <Card className="full-height">
                <CardBody >
                        <Card>
                                <CardHeader>
                                        <CardTitle className="d-flex align-content-center">
                                                <div className="general-card-title-img text-primary mr-2">
                                                        <i className="icon-TP_KI_Logo_White" />
                                                </div>
                                                <div className="general-card-title text-primary">{t('title')}</div>
                                        </CardTitle>
                                </CardHeader>

                                <CardBody>
                                        <h5 className="general-card-subtitle">{t('subtitle')}</h5>
                                        <div>{t('description')}</div>
                                </CardBody>

                                <CardBody>
                                        <div className="divRadiusCont">
                                                <p>{t('my_reviews')}</p>
                                                <WidgetDoughnut data={ data1 } dataTitle={ title1 } />
                                                <WidgetDoughnut data={ data2 } dataTitle={ title2 } />
                                        </div>
                                        <div className="divRadiusCont">
                                                <p>{t('all_reviews')}</p>
                                                <WidgetDoughnut data={ data3 } dataTitle={ title3 } />
                                                <WidgetDoughnut data={ data4 } dataTitle={ title4 } />
                                        </div>
                                </CardBody>
                        </Card>
                </CardBody>
        </Card>
        );
};

export default Stats;